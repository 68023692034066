@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Hind+Vadodara:wght@300&family=Tajawal:wght@200&display=swap');


.paypal-container {
    margin-top: 20px; /* Adjust as needed */
  }

.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 24px;
}

.error-message {
  background-color: #ffcccc;
  color: #990000;
  padding: 10px;
  border: 1px solid #ff6666;
  margin-top: 10px;
}

/* Step 3: Define message style */
.success-message {
  background-color: #ffcccc;
  color: #000099;
  padding: 10px;
  border: 1px solid #6666ff;
  margin-top: 10px;
}

.signup-label{
    font-family: system-ui;
    font-size:16px;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.signup-input {
    width: 350px;
    padding-bottom: 20px; 
    box-sizing: border-box;
    border-radius: 6px;
    margin: 5px 0;
    padding: 14px 16px;
    border: 2px solid #dddfe2;
}

#signup-button {
    cursor: pointer;
    font-family:system-ui;
    font-weight: 600;
    border: none;
    background-color: #448cf8;
    color: white;
    width: 100%;
    height: 2.4em;
    line-height: 48px;
    margin-top: 20px;
    font-size: 20px;
    border-radius: 6px;
    padding: 0 16px;
}

#signup-header{
    display: flex;
    font-size: 64px; 
    justify-content: center;
    margin: 0;
    padding-top: 80px;
    font-family: 'DM Serif Display serif';
}

#signup-subheader{
    display: flex;
    font-size: 32px;
    justify-content: center;
    margin: 12px; 
    font-family: 'DM Serif Display', serif; 
}

p {
    font-family: 'Hind Vadodara', sans-serif; /* Apply Hind Vadodara font to <p> tags */
}

@media screen and (min-width: 0px) and (max-width: 768px)
{
    #signup-header{
        font-size: 48px;
    }

    #signup-subheader{
        font-size: 24px;
    }
}