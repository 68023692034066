.ui.cards{
    /* Might use later please keep */
}

.ui.cards>.card {
    cursor: pointer;
}

#profile-container {
    vertical-align: top;
    top: 1em;
    width: 100%;
    position: relative;
    /* left: 5%; */
}

#profile-image{
    position: relative;
    display: inline-block;
    /* left: 1em; */
}

#profile-header{
    position: relative;
    display: inline-block;
    top: 1.8em;
    /* left: 1.5em; */
}

#profile-body{
    position: relative;
    /* top: 3.5em; */
    /* width: 90%;  */
    display: inline-block;
}

#profile-cards{
    margin: 0 10%;
    position: relative;
    /* left: 5%; */
}

@media (max-width: 1000px) {
    .ui.cards{
        justify-content: center;
    }
}