.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 24px;
}

.login-label{
    font-family:system-ui;
    font-size:16px;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.login-input {
    width: 350px;
    padding-bottom: 20px; 
    box-sizing: border-box;
    border-radius: 6px;
    margin: 5px 0;
    padding: 14px 16px;
    border: 2px solid #dddfe2;
}

#login-button {
  cursor: pointer;
  font-family:system-ui;
  font-weight: 600;
  border: none;
  background-color: #448cf8;
  color: white;
  width: 100%;
  height: 2.4em;
  line-height: 48px;
  margin-top: 20px;
  font-size: 20px;
  border-radius: 6px;
  padding: 0 16px;
}


#login-header{
    display: flex;
    font-size: 64px; 
    justify-content: center;
    margin: 0;
    padding-top: 80px;
    font-family: 'DM Serif Display serif';
}

#login-subheader{
    display: flex;
    font-size: 32px;
    justify-content: center;
    margin: 12px; 
    font-family: 'DM Serif Display', serif; 
}

@media screen and (min-width: 0px) and (max-width: 768px)
{
    #login-header{
        font-size: 48px;
    }

    #login-subheader{
        font-size: 24px;
    }
}